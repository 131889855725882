import * as React from 'react';
import { type ProfileProductResponse } from 'bb/account/types';
import { AmountPerMonth, Text } from 'bb/i18n';

export type ProfilePricesPerSubscriptionProps = {
    products: ProfileProductResponse[];
};

export const ProfilePricesPerSubscription = ({
    products
}: ProfilePricesPerSubscriptionProps) => {
    // If we only have 1 product we use a translation that omits the displayname
    const translation =
        products.length > 1
            ? 'myAccount:pricePerProfileForSubscription'
            : 'myAccount:pricePerProfile';

    return (
        <ul>
            {products.map(
                (product: ProfileProductResponse) =>
                    product.profileprice != null && (
                        <li key={`${product.displayname}-price`}>
                            <Text t={translation} as="span">
                                <AmountPerMonth
                                    amount={
                                        product.originalprofileprice ||
                                        product.profileprice
                                    }
                                />
                                {product.displayname}
                            </Text>
                        </li>
                    )
            )}
        </ul>
    );
};
